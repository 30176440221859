import { defineStore } from "pinia";

export const useProfilesStore = defineStore("profiles", {
  state: () => ({
    profiles: [],

    // whosWatching |
    step: "whosWatching",

    selectedProfile: null,
  }),
  getters: {
    profilesWithInitials() {
      return this.profiles
        .map((p) => {
          return {
            ...p,
            initials: p.name.substring(0, 1).toUpperCase(),
          };
        })
        .filter((p) => p.external_id !== null);
    },
    sortedInitials() {
      const list = [...this.profilesWithInitials];

      const sortedList = list.sort((a, b) => {
        if (a.isMain && !b.isMain) {
          return -1;
        } else if (!a.isMain && b.isMain) {
          return 1;
        } else if (a.isKid && !b.isKid) {
          return 1;
        } else if (!a.isKid && b.isKid) {
          return -1;
        } else {
          return 0;
        }
      });

      return sortedList;
    },
    parsedProfiles() {
      const { t } = useI18n();
      const list = [...this.sortedInitials];
      if (list.length < 5) {
        list.push({ id: null, name: t("Add New") });
      }

      return list;
    },
    adultAccounts() {
      return this.parsedProfiles.filter((p) => !p.isKid);
    },
    kidAccounts() {
      return this.parsedProfiles.filter((p) => p.isKid);
    },
  },

  actions: {
    signOut() {
      this.profiles = [];
      this.selectedProfile = null;
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
